import React, { useContext, useState, useEffect, useRef } from "react";
import tw from "twin.macro";
import Img from "react-cloudinary-lazy-image";
import { Transition } from "@headlessui/react";
import { Link, useIntl } from "gatsby-plugin-intl";
import { FirebaseAuthContext } from "../../context/FirebaseAuthContext";
import { css, cx } from "@emotion/css";
import firebase from "gatsby-plugin-firebase";

const Header = ({ title }) => {
    const intl = useIntl();
    const [showProfileDropDown, setShowProfileDropDown] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const { userData, setUserData } = useContext(FirebaseAuthContext);
    const user = userData;

    // Desconectamos sesión
    const handleDisconnect = async (event) => {
        event && event.preventDefault();
        firebase
            .auth()
            .signOut()
            .then(() => {
                setUserData(undefined);
            });
    };

    // Definimos Classe Dropdown Menu Animation
    const dropdownMenuTwClassNames = {
        enter: cx(css(tw`transition ease-out duration-100`)),
        enterFrom: cx(css(tw`transform opacity-0 scale-95`)),
        enterTo: cx(css(tw`transform opacity-100 scale-100`)),
        leave: cx(css(tw`transition ease-in duration-75`)),
        leaveFrom: cx(css(tw`transform opacity-100 scale-100`)),
        leaveTo: cx(css(tw`transform opacity-0 scale-95`)),
    };

    // referncia a ShowProfileDiv
    const showProfileDropDownDiv = useRef();

    // Ponemos un eventListener para capturar un clic fuera del menu de profile
    useEffect(() => {
        if (showProfileDropDown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showProfileDropDown]);

    const handleClickOutside = (e) => {
        if (showProfileDropDownDiv.current && showProfileDropDownDiv.current.contains(e.target)) {
            return;
        }

        setShowProfileDropDown(false);
    };

    const linkActive = tw`bg-primary`;

    return (
        <>
            <div css={[tw`bg-quaternary pb-32`]}>
                <nav css={[tw`bg-quaternary`]}>
                    {/** Menú principal */}
                    <div css={[tw`max-w-7xl mx-auto sm:px-6 lg:px-8`]}>
                        <div css={[tw`border-b border-gray-300 border-opacity-25`]}>
                            <div css={[tw`flex items-center justify-between h-16 px-4 sm:px-0`]}>
                                <div css={[tw`flex items-center`]}>
                                    {/** Avatar usuari */}
                                    <div css={[tw`flex-shrink-0 h-8 w-8`]}>
                                        <Img
                                            css={[tw`duration-300`]}
                                            cloudName="moybu"
                                            imageName={`bdtmm/dashboard/logos/logo-rounded_pzmddv`}
                                            fluid={{
                                                maxWidth: 512,
                                            }}
                                            //urlParams={'g_face,c_lfill'}
                                            alt={"logo bdtmm"}
                                        />
                                    </div>

                                    {/** Opciones principales */}
                                    <div css={[tw`hidden md:block`]}>
                                        <div
                                            css={[
                                                tw`font-montserrat m-10 flex items-baseline space-x-4 hover:text-white`,
                                            ]}
                                        >
                                            <Link
                                                to="/"
                                                activeStyle={linkActive}
                                                css={[
                                                    tw`duration-300 text-white px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700`,
                                                ]}
                                            >
                                                {intl.formatMessage({ id: "23" })}
                                            </Link>

                                            <Link
                                                to="/notices/create"
                                                css={[
                                                    tw`duration-300 text-white px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700`,
                                                ]}
                                            >
                                                {intl.formatMessage({ id: "31" })}
                                            </Link>

                                            <Link
                                                to="/skills/create"
                                                css={[
                                                    tw`duration-300 text-white px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700`,
                                                ]}
                                            >
                                                {intl.formatMessage({ id: "48" })}
                                            </Link>

                                            {/*<Link
                                                to="/skills/create"
                                                css={[
                                                    tw`duration-300 text-white px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700`,
                                                ]}
                                            >
                                                {intl.formatMessage({ id: "48" })}
                                            </Link>

                                            <Link
                                                to="/notices/create"
                                                css={[
                                                    tw`duration-300 text-white px-3 py-2 rounded-md text-sm font-medium hover:text-white hover:bg-gray-700`,
                                                ]}
                                            >
                                                {intl.formatMessage({ id: "31" })}
                                            </Link>
                                            */}
                                        </div>
                                    </div>
                                </div>

                                {/** Opciones emergentes usuario */}
                                <div css={[tw`hidden md:block`]}>
                                    <div css={[tw`ml-4 flex items-center md:ml-6`]}>
                                        <span></span>
                                        {/*
                                        <button
                                            onClick={() => {
                                                changeLocale("es");
                                            }}
                                            css={[tw`mr-2 text-white font-montserrat text-sm`, intl.locale === "es" ? tw`font-semibold` : null]}
                                        >
                                            ES
                                        </button>

                                        <button
                                            onClick={() => {
                                                changeLocale("ca");
                                            }}
                                            css={[tw`mr-1 text-white font-montserrat text-sm`, intl.locale === "ca" ? tw`font-semibold` : null]}
                                        >
                                            CA
                                        </button>*/}

                                        {/* Profile dropdown */}
                                        <div css={[tw`ml-3 relative`]}>
                                            <div css={[tw``]}>                                                
                                                <button
                                                    css={[
                                                        tw`max-w-xs rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`,
                                                    ]}
                                                    id="user-menu"
                                                    aria-haspopup="true"
                                                    onClick={() => {
                                                        setShowProfileDropDown(
                                                            !showProfileDropDown,
                                                        );
                                                    }}
                                                >
                                                    <span css={[tw`inline mr-3 text-white font-montserrat uppercase text-xs font-semibold`]}>{user.name}</span>
                                                    <span css={[tw`sr-only`]}>
                                                        {intl.formatMessage({ id: "26" })}
                                                    </span>
                                                    <img
                                                        loading="lazy"
                                                        css={[tw`h-8 w-8 rounded-full`]}
                                                        alt={user.name}
                                                        src={user.picture || user.avatar}
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = require("../../images/misc/unknown.png");
                                                        }}
                                                    />
                                                </button>
                                            </div>

                                            {/** Dropdown menú */}
                                            <div ref={showProfileDropDownDiv}>
                                                <Transition
                                                    show={showProfileDropDown}
                                                    {...dropdownMenuTwClassNames}
                                                >
                                                    {(ref) => (
                                                        <div
                                                            ref={ref}
                                                            css={[
                                                                tw`font-montserrat origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5`,
                                                            ]}
                                                            role="menu"
                                                            aria-orientation="vertical"
                                                            aria-labelledby="user-menu"
                                                        >
                                                            <button
                                                                css={[
                                                                    tw`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left`,
                                                                ]}
                                                                role="menuitem"
                                                                onClick={(e) => {
                                                                    handleDisconnect(e);
                                                                }}
                                                            >
                                                                {intl.formatMessage({ id: "24" })}
                                                            </button>
                                                        </div>
                                                    )}
                                                </Transition>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/** Menú mobile */}
                                <div css={[tw`-mr-2 flex md:hidden`]}>
                                    {/** Mobile menú button */}
                                    <button
                                        css={[
                                            tw`bg-quaternary duration-300 inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`,
                                        ]}
                                        onClick={() => {
                                            setShowMobileMenu(!showMobileMenu);
                                        }}
                                    >
                                        <span css={[tw`sr-only`]}>
                                            {intl.formatMessage({ id: "27" })}
                                        </span>
                                        {/** 
                                            Heroicon name: menu
                                            Menu open: "hidden", Menu closed: "block"
                                        */}
                                        {!showMobileMenu && (
                                            <svg
                                                css={[tw`block h-6 w-6`]}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M4 6h16M4 12h16M4 18h16"
                                                />
                                            </svg>
                                        )}

                                        {/**                                                             
                                                    Heroicon name: x
                                                    Menu open: "block", Menu closed: "hidden"
                                                    */}
                                        {showMobileMenu && (
                                            <svg
                                                css={[tw`h-6 w-6`]}
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/** Menú lateral */}
                    <MobileMenu
                        showMobileMenu={showMobileMenu}
                        user={user}
                        handleDisconnect={handleDisconnect}
                    />
                </nav>

                {/** Header title */}
                <header css={[tw`py-10`]}>
                    <div css={[tw`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8`]}>
                        <h1 css={[tw`text-3xl font-bold text-white font-montserrat`]}>{title}</h1>
                    </div>
                </header>
            </div>
        </>
    );
};

const MobileMenu = ({ showMobileMenu, user, handleDisconnect }) => {
    const intl = useIntl();

    return (
        <>
            {/**
                Mobile menu, toggle classes based on menu state.
                Open: "block", closed: "hidden"
            */}
            {showMobileMenu && (
                <div css={[tw`font-montserrat border-b border-gray-700 md:hidden`]}>
                    <div css={[tw`px-2 py-3 space-y-1 sm:px-3`]}>
                        <Link
                            to="/"
                            css={[
                                tw`bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium`,
                            ]}
                        >
                            {intl.formatMessage({ id: "23" })}
                        </Link>
                    </div>
                    <div css={[tw`pt-4 pb-3 border-t border-gray-700`]}>
                        <div css={[tw`flex items-center px-5`]}>
                            <div css={[tw`flex-shrink-0`]}>
                                <img
                                    loading="lazy"
                                    css={[tw`h-10 w-10 rounded-full`]}
                                    src={user.picture || user.avatar}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = require("../../images/misc/unknown.png");
                                    }}
                                    alt={"A"}
                                />
                            </div>
                            <div css={[tw`ml-3`]}>
                                <div css={[tw`text-base font-medium leading-none text-white`]}>
                                    {user.alias}
                                </div>
                                <div css={[tw`text-sm font-medium leading-none text-gray-400`]}>
                                    {user.email}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                            <Link
                                onClick={(e) => {
                                    handleDisconnect(e);
                                }}
                                css={[
                                    tw`block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700`,
                                ]}
                            >
                                {intl.formatMessage({ id: "24" })}
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Header;
